$ = jQuery.noConflict();
$(document).ready(function () {
  $.get("https://events.nothelferkurs-engadin.ch/events", (res) => {
    res.data.map((course) => {
      let nothelferkurs = /Nothelferkurs/i;

      let blsaed = /BLS-AED/i;

      let container = $("#course-option-weiterer");

      if (nothelferkurs.test(course.label)) {
        container = $("#course-option-nothelferkurs");
      }

      if (blsaed.test(course.label)) {
        container = $("#course-option-blsaed");
      }

      container.find("p").remove();
      container.append(`
        <label class="w-100 select-option d-flex align-items-center">
          <input type="checkbox" name="events[]" value="${course.eventTime}%%${course.label}}" ><p>${course.label}</p>
        </label>
      `);
    });
  });

  function onSubmit(token) {
    let formData = $("#subscribe-form").serialize();

    $("#form-exception").empty();
    $(".g-recaptcha").hide();

    $.ajax({
      url: "https://events.nothelferkurs-engadin.ch/signup",
      method: "POST",
      data: formData,
      success: (res) => {
        $("#subscribe-form").hide();
        $("#form-exception").append(`
          <p> <i class="fa fa-check" aria-hidden="true"></i> Besten Dank für Ihre Anmeldung. <p>
        `);
      },
      error: (error) => {
        $(".g-recaptcha").show();
        $("#form-exception").append(`
            <p> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Bitte füllen Sie sämtliche Felder aus. Vielen Dank! </p>
          `);
      },
    });
  }

  window.onSubmit = onSubmit;
});
